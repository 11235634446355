// Gatsby stuff
import React from "react"
import { Link } from "gatsby"
import profileimage from '../images/profile3.png'
import emailjs from 'emailjs-com'

// Material Ui stuff
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import {Helmet} from "react-helmet";

//Image

import sayhello from '../images/mailbox.png'



//Google Fonts style
import '../styles/styles.scss';

import NavBar from '../components/NavBar';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  fontstyleaboutme: {
    fontFamily: ["'Short Stack'", "cursive"],
    padding: 20,
    marginTop: 20,
    fontSize: 30,
    
    
  },
  grid1 : {
    marginLeft: 60,
    marginTop: 0,
    marginRight: 0,
    height: 500,
  },
  grid2 : {
    marginTop: 0,

  },
  image : {
    '& .image-wrapper': {
      
      height:"8vh",
      width: "auto",
      },
      '& .profile-image': {
      background: "white",
      },
  }

}));

export default function SayHello() {
  const classes = useStyles()
  function sendEmail(e) {

    e.preventDefault();

    emailjs.sendForm('service_otp2y3m', 'template_gv4mqsb', e.target, 'user_KfRpus6VLjZUXQLeqmo9k')
      .then((result) => {
         alert("Form successfully submitted")
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  }

  return (
    <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>MN - SayHello</title>
                <link rel="canonical" href="http://malvinnathan.com" />
            </Helmet>
    <NavBar/>

   

    <Grid container>

    <Grid item  xs={12} sm={12} md={12} lg={12}>
        <Typography className={classes.fontstyleaboutme} alignItems='left' variant='h1'>
          Hello there!
        </Typography>
        
      </Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.grid1}>
          <Typography className={classes.fontstyleaboutme} alignItems='left' variant='h1'>
              Get in touch
            </Typography>
            <form onSubmit= {sendEmail} className={classes.root}>
              
              <TextField
              id="firstname"
              name="firstname"
              label=" First Name"
              style={{ margin: 8, width: "35%"}}
              color= "primary"
              margin="normal"
              halfWidth
              InputLabelProps={{
                shrink: true,
              }}
              />
              <TextField
              id="lastname"
              name="lastname"
              label=" Last Name"
              style={{ margin: 8, width: "35%"}}
              color= "primary"
              margin="normal"
              halfWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
              <br />
              <TextField
              id="email"
              name="email"
              label=" Email"
              style={{ margin: 8, width: "70%"}}
              color= "primary"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
              <br />
              <TextField
              id="subject"
              name="subject"
              label=" Subject"
              style={{ margin: 8, width: "70%"}}
              color= "primary"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <br /> <br/>

              <TextField
              id="message"
              name="message"
              label="Message"
              style={{width: "90%"}}
              multiline
              rows={5}
              defaultValue="Hi Malvin,"
              variant="outlined"
            />
            <br/><br/>
            <Button type="submit" value="Submit" style={{float:'right'}}>Submit</Button>
            <Button type="reset" value="Reset" style={{float:'right'}}>Reset</Button>
            
            
        </form>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} className={classes.grid2}> 
        <div className={classes.image}>
          <div className='image-wrapper'>
            <img src={sayhello} alt="sayhello" className="profile-image"/>
          </div>
        </div>
        
      </Grid>

      
    </Grid>
  
    

  
    

    
    </>

  )

}

 